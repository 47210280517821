// Endpoints constants
export const successStatus = 200
export const failureStatus = 400

// Rols constants
export const ROL_COMPRADOR = {
	nombreRol: 'COMPRADOR',
	rolId: 5,
}
export const ROL_USUARIO = {
	nombreRol: 'USUARIO',
	rolId: 6,
}
export const ROL_CUIDADOR_FAMILIAR = {
	nombreRol: 'CUIDADOR_FAMILIAR',
	rolId: 7,
}
export const ROL_CUIDADOR_PROFESIONAL = {
	nombreRol: 'CUIDADOR_PROFESIONAL',
	rolId: 8,
}
export const ROL_INSTITUCION = {
	nombreRol: 'INSTITUCION',
	rolId: 9,
}
export const ROL_INTERESADO = {
	nombreRol: 'INTERESADO',
	rolId: 10,
}
export const ROL_ADMINISTRADOR_INSTITUCION = {
	nombreRol: 'ADMINISTRADOR-INSTITUCION',
	rolId: 20,
}
export const ROL_COLABORADOR_INSTITUCION = {
	nombreRol: 'COLABORADOR-INSTITUCION',
	rolId: 21,
}

// Form validation constants
export const NAME_PATTERN = /^[A-Za-zÀ-ž]+(?:\s[A-Za-zÀ-ž]+)*$/
export const NAME_NAVIDAD_PATTERN = /^[A-Za-zÀ-ž\s]+(?:[A-Za-zÀ-ž]+\s)*$/
export const INSTITUTION_NAME_PATTERN = /^[A-Za-z0-9À-ž\s.,()-]+$/
export const EMAIL_PATTERN = /^(([^<>()\]\\.,;:=\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/
export const FORMAT_NUMBER_PATTERN = /(\d)(?=(\d{3})+(?!\d))/g
export const NUMBER_PATTERN = /^[0-9]*$/i
export const NOSCRIPT_PATTERN = /^[ a-zA-Z0-9#°áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ,.;:()¿?¡!_-]+$/
export const DOCUMENT_PATTERN = /^[a-zA-Z0-9]+$/
export const NO_SPECIAL_CHARACTERS_PATTERN  = /^[A-Za-z0-9À-ž]+(?:\s[A-Za-z0-9À-ž]+)*$/
export const ADDRESS_PATTERN = /^(?!.*\s{2})[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ0-9.,#-\s]*$/
export const FLOOR_PATTERN = /^(?=.*[a-zA-Z0-9ñÑ])[a-zA-Z0-9ñÑ°]+(?:\s+[a-zA-Z0-9ñÑ°]+)*$/
export const DEPARTMENT_PATTERN = /^(?=.*[a-zA-Z0-9ñÑ])[a-zA-Z0-9ñÑ.°-]+(?:\s+[a-zA-Z0-9ñÑ.°-]+)*$/
export const ADITIONAL_INFO_PATTERN = /^(?!.*\s{2})[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ0-9.,°/-\s]*$/
export const NAME_MIN_LENGTH = 3
export const PASSWORD_MIN_LENGTH = 6
export const PASSWORD_MAX_LENGTH = 40
export const CELLPHONE_LENGTH = 10
export const YEAR_MAX_LENGTH = 4
export const YEAR_MAX = new Date().getFullYear()
export const YEAR_MIN = 1900
export const DATE_MAX_LENGTH = 2
export const DATE_MAX = 31
export const DATE_MIN = 1
export const QUANTITY_MIN = 1

// Api Google Key
export const API_GOOGLE_KEY = 'AIzaSyCN8ZrJwdNr5hJ4FkpgwMR6qzH1KS4TWlg'

// Other Consts
export const MONTHS = [
	'enero',
	'febrero',
	'marzo',
	'abril',
	'mayo',
	'junio',
	'julio',
	'agosto',
	'septiembre',
	'octubre',
	'noviembre',
	'diciembre',
]
export const MONTHS_SHORTNAME = [
	'Ene',
	'Feb',
	'Mar',
	'Abr',
	'May',
	'Jun',
	'Jul',
	'Ago',
	'Sep',
	'Oct',
	'Nov',
	'Dic',
]
export const STATUS_ORDERS = {
	ORIGEN: 'ORIGEN',
	REPARTO: 'REPARTO',
	ENTREGADO: 'ENTREGADO',
	DEVOLUCION: 'DEVOLUCION',
}
export const TYPE_CONTENT = { PRINCIPAL: 'principal', OTHER: 'other' }
export const MAX_FONT = 20
export const MIN_FONT = 16

export const DOCUMENT_TYPE_NIT = {
	text: 'NIT',
	id: 3
}